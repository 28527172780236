import {
    FontPalette,
    FontFiles,
    createTheme,
    ColorPalette,
    Theme,
    DefaultTheme,
} from "@assets/theme/Theme"
import { EncodedImages } from "../images/encodedImages"

// create the default theme

const fontPalette: FontPalette = {
    regular: "sf-pro-display",
    semiBold: "sf-pro-display-semibold",
    bold: "sf-pro-display-bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included

    // add custom fonts
    // "polls-Lyon-SemiBold": require("../fonts/LyonText-Semibold.otf"),

    // set custom icon font, or include default
    // default
    IcoMoon: require("@assets/icons/icomoon.ttf"),

    // custom
    // IcoMoon: require("../icons/icomoon.ttf"),
}

const colorPalette: ColorPalette = {
    primary: "#3164FF",
    secondary: "#FFB300",
    success: "#05CB4F",
    primaryText: "#121214",
    secondaryText: "#7B7B7B",
    background: "#E0EAFD",

    black: "#000000",
    white: "#FFFFFF",
    red: "#D0021B",
    blue: "#007AFE",
    darkGrey: "#9B9B9B",
    grey: "#D8D8D8",
    lightGrey: "#EBEBEB",
    flag: "FA6400",
    textColor: "#535C71",
    lightBlue: "#EFF5FF",

    //Polls Colors
    pollPrimaryTextColor: "#343E57",
}

export const TenantTheme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: colorPalette,
    fontSizeMultiplier: 1.0,
    lineHeightMultiplier: 1.0,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        // image
        // layout
        // lottie

        //old stuff to be removed
        // borderRadius
        // fontFiles

        color: {
            navHeaderBackground: colorPalette.white,
            optionCardProgressBarFill: colorPalette.primary,
            bottomNavigationFlagBackground: colorPalette.secondary,
            ctaPrimaryActiveBackground: colorPalette.primary,
            ctaPrimaryText: colorPalette.white,
            ctaFloatingText: colorPalette.primary,
            optionCardCheckbox: colorPalette.primary,
            checkBox: colorPalette.primary,
        },
        font:   {

            pollTitleActiveWeb: {
                ...DefaultTheme.font.pollTitleActiveWeb,
                color: colorPalette.pollPrimaryTextColor,
                fontFamily: fontPalette.bold
            },

            defaultCardTemplateTitle: {
                ...DefaultTheme.font.defaultCardTemplateTitle,
                fontFamily: fontPalette.bold,
                fontSize: 15,
                lineHeight: 18,
                color: colorPalette.pollPrimaryTextColor,
            },
            defaultCardTemplateSubtitle: {
                ...DefaultTheme.font.defaultCardTemplateSubtitle,
                fontFamily: fontPalette.semiBold,
                fontSize: 11,
                lineHeight: 14,
                color: colorPalette.primary,
            },
            defaultCardTemplateDescription: {
                ...DefaultTheme.font.defaultCardTemplateDescription,
                fontFamily: fontPalette.bold,
                fontSize: 12,
                lineHeight: 14,
                color: colorPalette.black,
            },


            step2OptionsSelectedCounterValid: {
                ...DefaultTheme.font.step2OptionsSelectedCounterValid,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: 36,
                lineHeight: 36,
            },
            step2OptionsSelectedCounterInvalid: {
                ...DefaultTheme.font.step2OptionsSelectedCounterInvalid,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.red,
                fontSize: 36,
                lineHeight: 36,
            },
            step2OptionsCounterText: {
                ...DefaultTheme.font.step2OptionsCounterText,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.textColor,
                fontSize: 14,
                lineHeight: 14,
            },
            step2FilterAll: {
                ...DefaultTheme.font.step2FilterAll,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: 14,
                lineHeight: 14,
            },
            step2FilterNone: {
                ...DefaultTheme.font.step2FilterNone,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: 14,
                lineHeight: 14,
            },
            pollListCopyBannerTitle: {
                ...DefaultTheme.font.pollListCopyBannerTitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.pollPrimaryTextColor,
                fontSize: 16,
                lineHeight: 16,
            },

            //Transcript View

            questionTitle: {
                ...DefaultTheme.font.questionTitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.pollPrimaryTextColor,
                lineHeight: 46,
                fontSize: 40,
            },

        },

        image: {
            appLogo: require("../images/trip.comLogo.png"), // top left corner of desktop
            // appBackgroundDesktop: `url(${EncodedImages.background})`,
            previewImageBackground: null,
            previewImageLogo: EncodedImages.logo, // top left of preview image
        },

        lottie: {
            checkmark: require("../lottie/checkmark.json"),
            //     checkmarkDone: require("../lottie/checkmark_green.json"),
            //     whiteSpinner: require("../lottie/WhiteLoadingSpinner.json"),
            spinner: require("../lottie/spinner.json"),
            //     trashCan: require("../lottie/trashCan.json"),
            checkBox: require("../lottie/checkBox.json"),
        },
    },
})

export const getThemeByQueryParam = (queryParam: string): Theme => {
    return TenantTheme
}
