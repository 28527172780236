import {
    FontPalette,
    FontFiles,
    createTheme,
    ColorPalette,
    Theme,
    DefaultTheme,
} from "@assets/theme/Theme"
import { EncodedImages } from "../images/encodedImages"

// create the default theme

const fontPalette: FontPalette = {
    //
    regular: "polls-Roboto-Regular",
    semiBold: "polls-Roboto-SemiBold",
    bold: "polls-Roboto-Bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included

    // add custom fonts
    "polls-Roboto-Regular": require("../fonts/Roboto-Regular.ttf"),
    "polls-Roboto-SemiBold": require("../fonts/Roboto-SemiBold.ttf"),
    "polls-Roboto-Bold": require("../fonts/Roboto-Bold.ttf"),

    // set custom icon font, or include default
    // default
    IcoMoon: require("@assets/icons/icomoon.ttf"),

    // custom
    // IcoMoon: require("../icons/icomoon.ttf"),
}

const colorPalette: ColorPalette = {
    // main colors
    primary: "#67A3A3", // main color of the theme used in general
    secondary: "#ebebeb", // accent color

    // backgrounds
    screenBackground: "#fff", // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
    cardBackground: "#fff", // all cards, option cards, settings cards, modals, drawers
    overlay: "#fff", // color used behind a mondal to "blur" the background

    // icons
    primaryIcon: "#111D30", // e.g. tab bar icons
    secondaryIcon: "#9b9b9b", // e.g. re-order, close

    // buttons
    primaryCtaBackground: "#67A3A3",
    primaryCtaBackgroundInactive: "#9b9b9b",
    primaryCtaTextActive: "#FFFFFF",
    primaryCtaTextDisabled: "#FFFFFF",

    secondaryCtaBackground: "#fff",
    secondaryCtaTextActive: "#111D30",
    secondaryCtaTextDisabled: "#111D30",

    tertiaryCtaBackground: "#", // tertiary cta (is this floating, or do we have tertiary AND floating)
    tertiaryCtaTextActive: "#",
    tertiaryCtaTextDisabled: "#",

    floatingCTAText: "#111D30",
    floatingCTATextDisabled: "#9b9b9b",

    // text
    t1: "#343E57", //all primary text, such as poll title, option card title, etc.
    t2: "#535C71", //all secondary text, such as help text, option subtitle, option details
    t3: "#9b9b9b", //help text and option details
    regularText: "#343E57",
    t1Inverted: "#fff",
    placeholder: "#ebebeb", //placeholder text before user types poll or option titles
    linkText: "#007AFE",
    error: "#FE281C",

    // misc
    shadow: "#1C1C1C", // color needed for dark shadows
    flag: "#0f0f0f", //color to draw attention such as submit to save changes banner
    divider: "#E0E0E0", //divider lines
    banner: "#EFF5FF", //banners such as the paste links banner
    destructive: "#FE281C", //for deleting things, typically red
    success: "#05CB4F",

    // Polls Colors (our palette)
    pollPrimaryTextColor: "#343E57",
}

export const TenantTheme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: colorPalette,
    fontSizeMultiplier: 1.0,
    lineHeightMultiplier: 1.0,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        // image
        // layout
        // lottie

        //old stuff to be removed
        // borderRadius
        // fontFiles

        color: {
            navHeaderBackground: colorPalette.cardBackground,
            optionCardProgressBarFill: colorPalette.primary,
            optionCardProgressBarBackground: colorPalette.secondary,
            bottomNavigationFlagBackground: colorPalette.flag,
            ctaPrimaryActiveBackground: colorPalette.primaryCtaBackground,
            ctaPrimaryText: colorPalette.primaryCtaTextActive,
            ctaFloatingText: colorPalette.primary, 
            optionCardCheckbox: colorPalette.primary,//checkbox on preview image and skeleton loader
            checkBox: colorPalette.primary,
            pollListCopyBannerIconBackground: colorPalette.screenBackground,
            pollListCopyBannerBackground: "#f3f0f1",
        },

        font: {
            //Poll Title
            pollTitleActiveWeb: {
                ...DefaultTheme.font.pollTitleActiveWeb,
                fontSize: 22,
                lineHeight: 25,
                color: colorPalette.t1,
            },

            pollTitleActiveMobile: {
                ...DefaultTheme.font.pollTitleActiveMobile,
                fontFamily: fontPalette.bold,
                fontSize: 19,
                lineHeight: 20,
                color: colorPalette.t1,
            },

            // Option Card
            optionCardTextVoteCount: {
                ...DefaultTheme.font.optionCardTextVoteCount,
                fontSize: 12,
                lineHeight: 10,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t2,
            },
            defaultCardTemplateTitle: {
                ...DefaultTheme.font.defaultCardTemplateTitle,
                fontFamily: fontPalette.bold,
                fontSize: 15,
                lineHeight: 18,
                color: colorPalette.t1,
            },
            defaultCardTemplateSubtitle: {
                ...DefaultTheme.font.defaultCardTemplateSubtitle,
                fontFamily: fontPalette.bold,
                fontSize: 13,
                lineHeight: 14,
                color: colorPalette.primary,
            },
            defaultCardTemplateDescription: {
                ...DefaultTheme.font.defaultCardTemplateDescription,
                fontFamily: fontPalette.semiBold,
                fontSize: 12,
                lineHeight: 14,
                color: colorPalette.t2,
            },
            defaultCardTemplateLink: {
                ...DefaultTheme.font.defaultCardTemplateLink,
                fontSize: 11,
                lineHeight: 10,
                fontFamily: fontPalette.semiBold,
            },

            ctaPrimaryText: {
                ...DefaultTheme.font.ctaPrimaryText,
                fontFamily: fontPalette.semiBold,
            },

            // Bottom Navigation Bar //
            bottomNavigationFlagText: {
                ...DefaultTheme.font.bottomNavigationFlagText,
                fontFamily: fontPalette.semiBold,
                fontSize: 13,
                lineHeight: 13,
                color: colorPalette.t1Inverted,
            }, // notification for the user after they have selected an option or made an update

            // poweredByHeader: {
            //     ...DefaultTheme.font.poweredByHeader,
            //     color: colorPalette.white,
            // },
            // poweredBySubheader: {
            //     ...DefaultTheme.font.poweredBySubheader,
            //     color: colorPalette.white,
            // },
            // sharePollTitle: {
            //     ...DefaultTheme.font.sharePollTitle,
            //     fontSize: 24,
            //     lineHeight: 30,
            //     fontFamily: fontPalette.bold,
            // },
            // createPollTitleWeb: {
            //     ...DefaultTheme.font.createPollTitleWeb,
            //     fontFamily: fontPalette.bold,
            //     fontSize: 24,
            //     lineHeight: 30,
            //     color: colorPalette.primaryText,
            // },

            // //Transcript View

            previewImagePollTitle: {
                ...DefaultTheme.font.previewImagePollTitle,
                color: colorPalette.t1,
                fontFamily: fontPalette.semiBold,
                fontSize: 34,
                lineHeight: 56,
            },
            previewImageOptionTitle: {
                ...DefaultTheme.font.previewImageOptionTitle,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 34,
                lineHeight: 42,
            },
            previewImageOptionSubtitle: {
                ...DefaultTheme.font.previewImageOptionSubtitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.primary,
                fontSize: 26,
                lineHeight: 40,
            },
            previewImageVotesCountText: {
                ...DefaultTheme.font.previewImageVotesCountText,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 24,
                lineHeight: 24,
            },

            // //Step 2 Controls

            step2OptionsSelectedCounterValid: {
                ...DefaultTheme.font.step2OptionsSelectedCounterValid,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: 36,
                lineHeight: 36,
            },
            // step2OptionsSelectedCounterInvalid: {
            //     ...DefaultTheme.font.step2OptionsSelectedCounterInvalid,
            //     fontFamily: fontPalette.semiBold,
            //     color: colorPalette.red,
            //     fontSize: 36,
            //     lineHeight: 36,
            // },
            step2OptionsCounterText: {
                ...DefaultTheme.font.step2OptionsCounterText,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 14,
                lineHeight: 14,
            },
            step2FilterAll: {
                ...DefaultTheme.font.step2FilterAll,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: 14,
                lineHeight: 14,
            },
            step2FilterNone: {
                ...DefaultTheme.font.step2FilterNone,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: 14,
                lineHeight: 14,
            },

            // //Step 2 Banner

            pollListCopyBannerTitle: {
                ...DefaultTheme.font.pollListCopyBannerTitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: 16,
                lineHeight: 16,
            },
            pollListCopyBannerSubtitle: {
                ...DefaultTheme.font.pollListCopyBannerSubtitle,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 10,
                lineHeight: 12,
            },

            // //Step 2 Option Card

            optionCardLinkTitle: {
                ...DefaultTheme.font.optionCardLinkTitle,
                fontFamily: fontPalette.semiBold,
                fontSize: 14,
                lineHeight: 16,
                color: colorPalette.t1,
            },

            optionCardLinkSubtitle: {
                ...DefaultTheme.font.optionCardLinkSubtitle,
                fontFamily: fontPalette.bold,
                fontSize: 11,
                lineHeight: 11,
                color: colorPalette.primary,
            },
        },

        image: {
            appLogo: require("../images/headerLogo.png"), // top left corner of desktop
            // appBackgroundDesktop: `url(${EncodedImages.background})`,
            previewImageLogo: EncodedImages.logo, // top left of preview image
            previewImageCard: require("@assets/images/preview-image/card.png"),
            previewImageBackground: null, // pick either light "null" or dark (require("../images/previewImageBackground.png"),)
            appBackgroundDesktop: "linear-gradient(180deg, #273E5E, #111D30)",
            postVotePromo: require("../images/promo.png"), 
            
        },

        lottie: {
            checkmark: require("../lottie/checkmark.json"),
            //     checkmarkDone: require("../lottie/checkmark_green.json"),
            //     whiteSpinner: require("../lottie/WhiteLoadingSpinner.json"),
            spinner: require("../lottie/spinner.json"),
            //     trashCan: require("../lottie/trashCan.json"),
            checkBox: require("../lottie/checkBox.json"),
            createPollSuccess: require("../lottie/createPollSuccess.json"),
        },
    },
})

export const getThemeByQueryParam = (queryParam: string): Theme => {
    return TenantTheme
}
