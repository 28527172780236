import {
    FontPalette,
    FontFiles,
    createTheme,
    ColorPalette,
    Theme,
    DefaultTheme,
} from "@assets/theme/Theme"
import { checkboxStyles } from "@components/Checkbox/Checkbox.styles"
import { EncodedImages } from "../images/encodedImages"

// create the default theme

const fontPalette: FontPalette = {
    regular: "polls-CircularStd-Regular",
    semiBold: "polls-CircularStd-SemiBold",
    bold: "polls-CircularStd-Bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included

    // add custom fonts
    "polls-CircularStd-Regular": require("../fonts/CircularStd-Regular.ttf"),
    "polls-CircularStd-SemiBold": require("../fonts/CircularStd-SemiBold.ttf"),
    "polls-CircularStd-Bold": require("../fonts/CircularStd-Bold.ttf"),
    "polls-PlayfairDisplay-Bold": require("../fonts/PlayfairDisplay-Bold.ttf"),
    // "polls-Lyon-SemiBold": require("../fonts/LyonText-Semibold.otf"),

    // set custom icon font, or include default
    // default
    IcoMoon: require("@assets/icons/icomoon.ttf"),

    // custom
    // IcoMoon: require("../icons/icomoon.ttf"),
}

const colorPalette: ColorPalette = {

    // main colors
    primary: "#F4D164", // main color of the theme used in general
    secondary: "#3A363C", // accent color

    // backgrounds
    screenBackground: "#fff", // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
    cardBackground: "#fff", // all cards, option cards, settings cards, modals, drawers
    overlay: "#fff", // color used behind a mondal to "blur" the background

    // icons
    primaryIcon: "#3A363C", // e.g. tab bar icons
    secondaryIcon: "#9b9b9b", // e.g. re-order, close

    // buttons
    primaryCtaBackground: "#3A363C",
    primaryCtaBackgroundInactive: "#9b9b9b",
    primaryCtaTextActive: "#FFFFFF",
    primaryCtaTextDisabled: "#FFFFFF",

    secondaryCtaBackground: "#fff",
    secondaryCtaTextActive: "#3A363C",
    secondaryCtaTextDisabled: "#3A363C",

    tertiaryCtaBackground: "#", // tertiary cta (is this floating, or do we have tertiary AND floating)
    tertiaryCtaTextActive: "#",
    tertiaryCtaTextDisabled: "#",

    floatingCTAText: "#3A363C",
    floatingCTATextDisabled: "#9b9b9b",

    // text
    t1: "#343E57", //all primary text, such as poll title, option card title, etc.
    t2: "#535C71", //all secondary text, such as help text, option subtitle, option details
    t3: "#9b9b9b", //help text and option details
    regularText: "#343E57",
    t1Inverted: "#fff",
    placeholder: "#ebebeb", //placeholder text before user types poll or option titles
    linkText: "#007AFE",
    error: "#FE281C",

    // misc
    shadow: "#1C1C1C", // color needed for dark shadows
    flag: "#FF6E61", //color to draw attention such as submit to save changes banner
    divider: "#E0E0E0", //divider lines
    banner: "#EFF5FF", //banners such as the paste links banner
    destructive: "#FE281C", //for deleting things, typically red
    success: "#05CB4F",

    // Polls Colors (our palette)
    pollPrimaryTextColor: "#343E57",

}

export const TenantTheme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: colorPalette,
    fontSizeMultiplier: 1.0,
    lineHeightMultiplier: 1.0,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        // image
        // layout
        // lottie

        //old stuff to be removed
        // borderRadius
        // fontFiles

        color: {
            skeletonLight: colorPalette.t2, //Skeleton secondary
            skeletonHighlight: colorPalette.t1Inverted,
            navHeaderBackground: colorPalette.screenBackground,
            optionCardProgressBarFill: colorPalette.primary,
            optionCardProgressBarBackground: colorPalette.placeholder,
            bottomNavigationFlagBackground: colorPalette.primary,
            ctaPrimaryActiveBackground: colorPalette.primaryCtaBackground,
            ctaPrimaryText: colorPalette.primaryCtaTextActive,
            ctaFloatingText: colorPalette.floatingCTAText,
            optionCardCheckbox: colorPalette.primary,
            checkBox: colorPalette.primary,
            pollListCopyBannerIconBackground: colorPalette.t1Inverted,
            pollListCopyBannerBackground: "#fef9f3",
        },

        font: {
           
            pollTitleActiveMobile: {
                ...DefaultTheme.font.pollTitleActiveMobile,
                fontFamily: fontPalette.bold,
                fontSize: 20,
                lineHeight: 22,
                color: colorPalette.t1,
            },
            pollTitleActiveWeb: {
                ...DefaultTheme.font.pollTitleActiveWeb,
                fontSize: 22,
                lineHeight: 22,
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
            },
            defaultCardTemplateTitle: {
                ...DefaultTheme.font.defaultCardTemplateTitle,
                fontSize: 15,
                lineHeight: 17,
                fontFamily: "polls-PlayfairDisplay-Bold",
                color:colorPalette.t1,
            },

            //Transcript View

            previewImagePollTitle: {
                ...DefaultTheme.font.previewImagePollTitle,
                color: colorPalette.t1,
                fontFamily: "polls-PlayfairDisplay-Bold",
                lineHeight: 60,
                fontSize: 40,
            },
            previewImageOptionTitle: {
                //preview image option title text
                ...DefaultTheme.font.previewImageOptionTitle,
                color: colorPalette.t1,
                fontSize: 42,
                lineHeight: 42,
                fontFamily: "polls-PlayfairDisplay-SemiBold",
            },
            previewImageOptionSubtitle: {
                ...DefaultTheme.font.previewImageOptionSubtitle,
                fontFamily: fontPalette.regular,
                color: "#B6ADA2",
                fontSize: 24,
                lineHeight: 32,
            },
            previewImageVotesCountText: {
                ...DefaultTheme.font.previewImageVotesCountText,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 24,
                lineHeight: 24,
            },


            defaultCardTemplateSubtitle: {
                ...DefaultTheme.font.defaultCardTemplateSubtitle,
                fontFamily: fontPalette.semiBold,
                fontSize: 11,
                lineHeight: 14,
                color: "#B6ADA2",
            },
            optionCardTextVoteCount: {
                ...DefaultTheme.font.optionCardTextVoteCount,
                fontSize: 11,
                lineHeight: 10,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1
            },
            defaultCardTemplateLink: {
                ...DefaultTheme.font.defaultCardTemplateLink,
                fontSize: 11,
                lineHeight: 10,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.linkText
            },
            ctaPrimaryText: {
                ...DefaultTheme.font.ctaPrimaryText,
                fontFamily: fontPalette.bold,
            },
            poweredByHeader: {
                ...DefaultTheme.font.poweredByHeader,
                color: colorPalette.pollPrimaryTextColor,
            },
            poweredBySubheader: {
                ...DefaultTheme.font.poweredBySubheader,
                color: colorPalette.pollPrimaryTextColor,
            },
            sharePollTitle: {
                ...DefaultTheme.font.sharePollTitle,
                fontSize: 24,
                lineHeight: 30,
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
            },
            createPollTitleWeb: {
                ...DefaultTheme.font.createPollTitleWeb,
                fontFamily: "polls-PlayfairDisplay-Bold",
                fontSize: 24,
                lineHeight: 30,
                color: colorPalette.t1,
            },

            //Step 2 Controls

            step2OptionsSelectedCounterValid: {
                ...DefaultTheme.font.step2OptionsSelectedCounterValid,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: 36,
                lineHeight: 36,
            },
            step2OptionsSelectedCounterInvalid: {
                ...DefaultTheme.font.step2OptionsSelectedCounterInvalid,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.error,
                fontSize: 36,
                lineHeight: 36,
            },
            step2OptionsCounterText: {
                ...DefaultTheme.font.step2OptionsCounterText,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 14,
                lineHeight: 14,
            },
            step2FilterAll: {
                ...DefaultTheme.font.step2FilterAll,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 14,
                lineHeight: 14,
            },
            step2FilterNone: {
                ...DefaultTheme.font.step2FilterNone,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 14,
                lineHeight: 14,
            },

            //Step 2 Banner

            pollListCopyBannerTitle: {
                ...DefaultTheme.font.pollListCopyBannerTitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: 15,
                lineHeight: 14,
            },
            pollListCopyBannerSubtitle: {
                ...DefaultTheme.font.pollListCopyBannerSubtitle,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 12,
                lineHeight: 11,
            },

            //Step 2 Option Card

            optionCardLinkTitle: {
                ...DefaultTheme.font.optionCardLinkTitle,
                fontFamily: "polls-PlayfairDisplay-Bold",
                fontSize: 16,
                lineHeight: 18,
                color: colorPalette.pollPrimaryTextColor,
            },

            optionCardLinkSubtitle: {
                ...DefaultTheme.font.optionCardLinkTitle,
                fontFamily: fontPalette.regular,
                fontSize: 11,
                lineHeight: 11,
                color: "#B6ADA2",
            },
        },

        image: {
            appLogo: require("../images/seatedLogo.png"), // top left corner of desktop
            // appBackgroundDesktop: `url(${EncodedImages.background})`,
            previewImageBackground: null,
            previewImageLogo: EncodedImages.logo,
            postVotePromo: require("../images/promo.png"), 
        },

        lottie: {
            checkmark: require("../lottie/checkmark.json"),
            //     checkmarkDone: require("../lottie/checkmark_green.json"),
            //     whiteSpinner: require("../lottie/WhiteLoadingSpinner.json"),
            spinner: require("../lottie/spinner.json"),
            //     trashCan: require("../lottie/trashCan.json"),
            checkBox: require("../lottie/checkBox.json"), 
            createPollSuccess: require("../lottie/createPollSuccess.json"),
        },
    },
})

export const getThemeByQueryParam = (queryParam: string): Theme => {
    return TenantTheme
}
