import {
    FontPalette,
    FontFiles,
    createTheme,
    ColorPalette,
    Theme,
    DefaultTheme,
} from "@assets/theme/Theme"
import { EncodedImages } from "../images/encodedImages"

// create the default theme

const fontPalette: FontPalette = {
    regular: "OpenSans-Regular",
    semiBold: "OpenSans-SemiBold",
    bold: "OpenSans-Bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included

    // add custom fonts
    "OpenSans-Regular": require("../fonts/OpenSans-Regular.ttf"),
    "OpenSans-SemiBold": require("../fonts/OpenSans-SemiBold.ttf"),
    "OpenSans-Bold": require("../fonts/OpenSans-Bold.ttf"),

    // set custom icon font, or include default
    // default
    IcoMoon: require("@assets/icons/icomoon.ttf"),

    // custom
    // IcoMoon: require("../icons/icomoon.ttf"),
}

const colorPalette: ColorPalette = {
    
     // main colors
     primary: "#3399FE", // main color of the theme used in general
     secondary: "#0286FF", // accent color
 
     // backgrounds
     screenBackground: "#fff", // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
     cardBackground: "#fff", // all cards, option cards, settings cards, modals, drawers
     overlay: "#fff", // color used behind a mondal to "blur" the background
 
     // icons
     primaryIcon: "#3399FE", // e.g. tab bar icons
     secondaryIcon: "#9b9b9b", // e.g. re-order, close
 
     // buttons
     primaryCtaBackground: "#0286FF",
     primaryCtaBackgroundInactive: "#9b9b9b",
     primaryCtaTextActive: "#FFFFFF",
     primaryCtaTextDisabled: "#FFFFFF",
 
     secondaryCtaBackground: "#fff",
     secondaryCtaTextActive: "#3399FE",
     secondaryCtaTextDisabled: "#3A363C",
 
     tertiaryCtaBackground: "#", // tertiary cta (is this floating, or do we have tertiary AND floating)
     tertiaryCtaTextActive: "#",
     tertiaryCtaTextDisabled: "#",
 
     floatingCTAText: "#3399FE",
     floatingCTATextDisabled: "#9b9b9b",
 
     // text
     t1: "#343E57", //all primary text, such as poll title, option card title, etc.
     t2: "#535C71", //all secondary text, such as help text, option subtitle, option details
     t3: "#9b9b9b", //help text and option details
     regularText: "#343E57",
     t1Inverted: "#fff",
     placeholder: "#ebebeb", //placeholder text before user types poll or option titles
     linkText: "#007AFE",
     error: "#FE281C",
 
     // misc
     shadow: "#1C1C1C", // color needed for dark shadows
     flag: "#07f0b3", //color to draw attention such as submit to save changes banner
     divider: "#E0E0E0", //divider lines
     banner: "#EFF5FF", //banners such as the paste links banner
     destructive: "#FE281C", //for deleting things, typically red
     success: "#05CB4F",
 
     // Polls Colors (our palette)
     pollPrimaryTextColor: "#343E57",

}

export const TenantTheme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: colorPalette,
    fontSizeMultiplier: 1.0,
    lineHeightMultiplier: 1.0,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        // image
        // layout
        // lottie

        //old stuff to be removed
        // borderRadius
        // fontFiles

        color: {
            navHeaderBackground: "#FFFFFF",
            optionCardProgressBarFill: colorPalette.primary,
            bottomNavigationFlagBackground: colorPalette.flag,
            optionCardProgressBarBackground: colorPalette.placeholder,
        
        },

        font: {
            previewImagePollTitle: {
                ...DefaultTheme.font.previewImagePollTitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                lineHeight: 54,
                fontSize: 37,
            },
            defaultCardTemplateTitle: {
                ...DefaultTheme.font.defaultCardTemplateTitle,
                fontFamily: fontPalette.bold,
                fontSize: 14,
                lineHeight: 17,
                color: colorPalette.t1
            },
            defaultCardTemplateSubtitle: {
                ...DefaultTheme.font.defaultCardTemplateSubtitle,
                fontFamily: fontPalette.semiBold,
                fontSize: 10,
                lineHeight: 14,
                color: colorPalette.t2,
            },
            defaultCardTemplateDescription: {
                ...DefaultTheme.font.defaultCardTemplateDescription,
                fontFamily: fontPalette.bold,
                fontSize: 10,
                lineHeight:  14,
                color: colorPalette.t3,
            },
            previewImageOptionTitle: {
                ...DefaultTheme.font.previewImageOptionTitle,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 35,
                lineHeight: 44,
            },
            previewImageOptionSubtitle: {
                ...DefaultTheme.font.previewImageOptionSubtitle,
                // fontFamily: fontPalette.regular,
                color: colorPalette.t2,
                fontSize: 28,
                lineHeight: 36,
            },
            optionCardTextVoteCount: {
                ...DefaultTheme.font.optionCardTextVoteCount,
                fontFamily: fontPalette.semiBold,
                fontSize: 11,
                lineHeight: 10,
                color: colorPalette.t1
            },
            defaultCardTemplateLink: {
                ...DefaultTheme.font.defaultCardTemplateLink,
                fontFamily: fontPalette.semiBold,
                fontSize: 11,
                lineHeight: 10,
                color: colorPalette.linkText
            },
            ctaPrimaryText: {
                ...DefaultTheme.font.ctaPrimaryText,
                fontFamily: fontPalette.semiBold,
            },
            sharePollTitle: {
                ...DefaultTheme.font.sharePollTitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: 24,
                lineHeight: 30,
            }, 
            optionCardLinkTitle: {
                ...DefaultTheme.font.optionCardLinkTitle,
                fontFamily: fontPalette.semiBold,
                fontSize: 15,
                lineHeight: 16,
                color: colorPalette.t1,
            },   
            optionCardLinkSubtitle: {
                ...DefaultTheme.font.optionCardLinkSubtitle,
                fontFamily: fontPalette.regular,
                fontSize: 10,
                lineHeight: 13,
                color: colorPalette.t2,
            },
            step2OptionsCounterText: {
                ...DefaultTheme.font.step2OptionsCounterText,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: 14,
                lineHeight: 14,
            },
        },

        image: {
            appLogo: require("../images/appHeaderLogo.png"), // top left corner of desktop
            // appBackgroundDesktop: `url(${EncodedImages.background})`,
            previewImageBackground: null,
            previewImageLogo: EncodedImages.logo,
        },

         lottie: {
            checkmark: require("../lottie/checkmark.json"),
        //     checkmarkDone: require("../lottie/checkmark_green.json"),
        //     whiteSpinner: require("../lottie/WhiteLoadingSpinner.json"),
             spinner: require("../lottie/spinner.json"),
        //     trashCan: require("../lottie/trashCan.json"),
             checkBox: require("../lottie/checkBox.json"),
             createPollSuccess: require("../lottie/createPollSuccess.json")
         },
    },
})

export const getThemeByQueryParam = (queryParam: string): Theme => {
    return TenantTheme
}
