import { Theme, TenantTheme as DefaultTheme } from "@assets/theme/Theme"
import { TenantTheme as PinktadaTenantTheme } from "@assets/../pinktada/theme/Theme"
import { TenantTheme as SeatedTenantTheme } from "@assets/../seated/theme/Theme"
import { TenantTheme as TuroTenantTheme } from "@assets/../turo/theme/Theme"
import { TenantTheme as UdriveTenantTheme } from "@assets/../udrive/theme/Theme"
import { TenantTheme as TickPickTenantTheme } from "@assets/../tickpick/theme/Theme"
import { TenantTheme as TripTenantTheme } from "@assets/../trip/theme/Theme"
import { TenantTheme as GametimeTenantTheme } from "@assets/../gametime/theme/Theme"
import { TenantTheme as DaycationTenantTheme } from "@assets/../daycation/theme/Theme"
import { TenantTheme as VividSeatsTenantTheme } from "@assets/../vividseats/theme/Theme"
import { TenantTheme as FlairTenantTheme } from "@assets/../flair/theme/Theme"
import { TenantTheme as MusementTenantTheme } from "@assets/../musement/theme/Theme"
import { TenantTheme as ClassPassTenantTheme } from "@assets/../classpass/theme/Theme"
import { TenantTheme as YelpTenantTheme } from "@assets/../yelp/theme/Theme"
import { TenantTheme as StayVistaTenantTheme } from "@assets/../stayvista/theme/Theme"
import { TenantTheme as SightSeeingTenantTheme } from "@assets/../sightseeing/theme/Theme"
import { TenantTheme as SwimplyTenantTheme } from "@assets/../swimply/theme/Theme"
import { TenantTheme as WuwwuwTenantTheme } from "@assets/../wuwwuw/theme/Theme"
import { TenantTheme as MarriottTenantTheme } from "@assets/../marriott/theme/Theme"
import { TenantTheme as SeatGeekTenantTheme } from "@assets/../seatgeek/theme/Theme"
import { TenantTheme as KasaTenantTheme } from "@assets/../kasa/theme/Theme"
import { TenantTheme as WagrTenantTheme } from "@assets/../wagr/theme/Theme"
import { TenantTheme as NordstromTenantTheme } from "@assets/../nordstrom/theme/Theme"
import { TenantTheme as PropSwapTenantTheme } from "@assets/../propswap/theme/Theme"
import { TenantTheme as GetawayTenantTheme } from "@assets/../getaway/theme/Theme"
import { TenantTheme as MyRegistryTenantTheme } from "@assets/../myregistry/theme/Theme"
import { TenantTheme as VOMOTenantTheme } from "@assets/../vomo/theme/Theme"
import { TenantTheme as EtsyTenantTheme } from "@assets/../etsy/theme/Theme"
import { TenantTheme as TwelfthTribeTenantTheme } from "@assets/../12thtribe/theme/Theme"
import { TenantTheme as TablTenantTheme } from "@assets/../tabl/theme/Theme"
import { TenantTheme as SoulCycleTenantTheme } from "@assets/../soulcycle/theme/Theme"
import { TenantTheme as WeWardTenantTheme } from "@assets/../weward/theme/Theme"
import { TenantTheme as BeginTenantTheme } from "@assets/../begin/theme/Theme"
import { TenantTheme as LittlePassportsTenantTheme } from "@assets/../littlepassports/theme/Theme"
import { TenantTheme as PackedTenantTheme } from "@assets/../packed/theme/Theme"
import { TenantTheme as EvolveTenantTheme } from "@assets/../evolve/theme/Theme"
import { TenantTheme as SustainoTenantTheme } from "@assets/../sustaino/theme/Theme"
import { TenantTheme as GetMyBoatTenantTheme } from "@assets/../getmyboat/theme/Theme"
import { TenantTheme as ShiseidoTenantTheme } from "@assets/../shiseido/theme/Theme"
import { TenantTheme as FuegoTenantTheme } from "@assets/../fuego/theme/Theme"
import { TenantTheme as TopSportTenantTheme } from "@assets/../topsport/theme/Theme"
import { TenantTheme as CoordleTenantTheme } from "@assets/../coordle/theme/Theme"
import { TenantTheme as AshandErieTenantTheme } from "@assets/../ashanderie/theme/Theme"
import { TenantTheme as PoshmarkTenantTheme } from "@assets/../poshmark/theme/Theme"
import { TenantTheme as BetrTenantTheme } from "@assets/../betr/theme/Theme"
import { TenantTheme as TravelTenantTheme } from "@assets/../travel/theme/Theme"
import { TenantTheme as TicketFairyTenantTheme } from "@assets/../ticketfairy/theme/Theme"
import { TenantTheme as TicketTenantTheme } from "@assets/../ticket/theme/Theme"
import { TenantTheme as DoorDashTenantTheme } from "@assets/../doordash/theme/Theme"
import { TenantTheme as IBCosmeticsTenantTheme } from "@assets/../ibcosmetics/theme/Theme"
import { TenantTheme as VoteTenantTheme } from "@assets/../vote/theme/Theme"
import { TenantTheme as BulovaTenantTheme } from "@assets/../bulova/theme/Theme"
import { TenantTheme as MovadoTenantTheme } from "@assets/../movado/theme/Theme"
import { TenantTheme as PicklebetTenantTheme } from "@assets/../picklebet/theme/Theme"
import { TenantTheme as DessyTenantTheme } from "@assets/../dessy/theme/Theme"
import { TenantTheme as HarryandDavidTenantTheme } from "@assets/../harryanddavid/theme/Theme"
import { TenantTheme as DabbleTenantTheme } from "@assets/../dabble/theme/Theme"
import { TenantTheme as RetrofeteTenantTheme } from "@assets/../retrofete/theme/Theme"
import { TenantTheme as EveryPrettyTenantTheme } from "@assets/../everpretty/theme/Theme"

export const getThemeByQueryParam = (queryParam: string): Theme => {
    const dynamicThemeImports: { [key: string]: Theme } = {
        pinktada: PinktadaTenantTheme,
        seated: SeatedTenantTheme,
        turo: TuroTenantTheme,
        udrive: UdriveTenantTheme,
        tickpick: TickPickTenantTheme,
        trip: TripTenantTheme,
        gametime: GametimeTenantTheme,
        daycation: DaycationTenantTheme,
        vividseats: VividSeatsTenantTheme,
        flair: FlairTenantTheme,
        musement: MusementTenantTheme,
        classpass: ClassPassTenantTheme,
        yelp: YelpTenantTheme,
        stayvista: StayVistaTenantTheme,
        sightseeing: SightSeeingTenantTheme,
        swimply: SwimplyTenantTheme,
        wuwwuw: WuwwuwTenantTheme,
        marriott: MarriottTenantTheme,
        seatgeek: SeatGeekTenantTheme,
        kasa: KasaTenantTheme,
        wagr: WagrTenantTheme,
        nordstrom: NordstromTenantTheme,
        propswap: PropSwapTenantTheme,
        getaway: GetawayTenantTheme,
        myregistry: MyRegistryTenantTheme,
        vomo: VOMOTenantTheme,
        etsy: EtsyTenantTheme,
        "12thtribe": TwelfthTribeTenantTheme,
        tabl: TablTenantTheme,
        soulcycle: SoulCycleTenantTheme,
        weward: WeWardTenantTheme,
        begin: BeginTenantTheme,
        littlepassports: LittlePassportsTenantTheme,
        packed: PackedTenantTheme,
        evolve: EvolveTenantTheme,
        sustaino: SustainoTenantTheme,
        getmyboat: GetMyBoatTenantTheme,
        shiseido: ShiseidoTenantTheme,
        fuego: FuegoTenantTheme,
        topsport: TopSportTenantTheme,
        coordle: CoordleTenantTheme,
        ashanderie: AshandErieTenantTheme,
        poshmark: PoshmarkTenantTheme,
        betr: BetrTenantTheme,
        travel: TravelTenantTheme,
        ticketfairy: TicketFairyTenantTheme,
        ticket: TicketTenantTheme,
        doordash: DoorDashTenantTheme,
        ibcosmetics: IBCosmeticsTenantTheme,
        vote: VoteTenantTheme,
        bulova: BulovaTenantTheme,
        movado: MovadoTenantTheme,
        picklebet: PicklebetTenantTheme,
        dessy: DessyTenantTheme,
        harryanddavid: HarryandDavidTenantTheme,
        dabble: DabbleTenantTheme,
        retrofete: RetrofeteTenantTheme,
        everpretty: EveryPrettyTenantTheme,
    }

    return dynamicThemeImports[queryParam] ?? TenantTheme
}

export const TenantTheme: Theme = {
    ...DefaultTheme,
}
